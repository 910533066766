<template>
    <div class="main-container">
        <h1>{{$t('myProject.multiPersonFreeCommunication')}}<!--多人免费沟通--></h1>
        <div class="people-flex">
            <div class="item" v-bind:key="index" v-for="(item, index) in list">
                <img :src="item.avatar" alt="" class="head_icon"/>
                <div class="name over">{{ item.name }}</div>
                <div class="company-position over">
                    {{ item.company_name }} · {{ item.position }}
                </div>
                <!-- 当前登录人talentId与多人沟通talent_id不一致时才展示立即购买与关注按钮，反之不展示 -->
                <div class="item-btn-group" v-if="talentId != item.talent_id">
                    <div @click="$router.push({path: '/user/index',query:{userType:2, talentId:item.talent_id,}})"
                         class="ibg-btn fl cur">
                        {{$t('myProject.buyNow')}}<!--立即购买-->
                    </div>
                    <div @click.prevent="attention($event,1,item)" class="ibg-btn fr cur" v-if="item.followed == 0"
                         v-preventReClick>
                        {{$t('myProject.immediateAttention')}}<!--立即关注-->
                    </div>
                    <div @click.prevent="attention($event,2,item)" class="ibg-btn fr cur" v-else v-preventReClick>
                        {{$t('myProject.unfollow')}}<!--取消关注--></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {followTalent} from "@/api/memberApi";

    export default {
        name: "MultiPersonFreeCommunication",
        data() {
            return {
                items: [1, 2, 3, 3, 3, 3, 3, 3],
            };
        },
        components: {},
        props: ["list", "talentId"],
        methods: {
            //关注
            attention(event, type, item) {
                if (event.target.disabled) {
                    // 点击太频繁了
                    this.$message.warning(this.$t('myProject.clickFrequentMsg'))
                    return
                }
                let formData = {
                    operateType: type,
                    talentId: item.id || item.talent_id,
                    talentName: item.name,
                    token: localStorage.getItem('token')
                }
                followTalent(formData).then((res) => {
                    if (res.code == 200) {
                        if (type == 1) {
                            item.followed = 1
                            this.$forceUpdate();
                            this.$message.success(this.$t("myProject.followMsg.followSuccess"))
                        } else {
                            item.followed = 0
                            this.$forceUpdate();
                            this.$message.success(this.$t("myProject.followMsg.unfollowSuccess"))
                        }
                    }
                })
            },
        }
    };
</script>

<style scoped>
    .main-container {
        border-top: 1px solid #e5e5e5;
        padding-top: 38px;
    }

    .main-container > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
    }

    .people-flex {
        margin-top: 45px;
        overflow: hidden;
    }

    .item {
        float: left;
        text-align: center;
        margin-right: 92px;
        margin-top: 104px;
    }

    .item:nth-child(-n + 4) {
        margin-top: 0;
    }

    .item:nth-child(4n) {
        float: left;
        text-align: center;
        margin-right: 0;
    }

    .item .head_icon {
        display: block;
        width: 120px;
        height: 120px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
        margin: 0 auto;
    }

    .item .name {
        width: 227px;
        text-align: center;
        margin-top: 18px;
        font-size: 20px;
        font-family: BentonSans;
        font-weight: 500;
        line-height: 23px;
        color: #000000;
    }

    .item .company-position {
        width: 227px;

        text-align: center;
        margin-top: 5px;

        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #949494;
    }

    .item .item-intro {
        width: 181px;
        text-align: center;
        margin: 5px auto 0;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #949494;
    }

    .item-btn-group {
        width: 227px;
        overflow: hidden;
        margin: 19px auto 0;
    }

    .ibg-btn {
        width: 100px;
        height: 40px;
        border: 1px solid #707070;
        border-radius: 25px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
    }
</style>
