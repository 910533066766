<template>
    <div class="container">
        <BreadcrumbNav :page-name="$t('myInterviewDetail.aPersonInterview')/*发起单人访谈*/"/>
        <div class="main">
            <div class="text-group2">
                <h1>{{details_data.project_name}}</h1>
                <div class="text-group2-content">
                    <!--<span class="publish-date">{{ // details_data.project_intro }}</span>-->
                    <span class="publish-date"><!--1天前发布--></span>
                    <span class="brief-intro over">{{details_data.project_intro}}</span>
                </div>
            </div>
            <div class="people-wrap">
                <div class="wrap1">
                    <div class="people-info">
                        <!-- <img class="head-icon fl" :src="details_data.avatar" alt="" /> -->
                        <img :src="details_data.initiator_avatar" alt="" class="head-icon fl"/>
                        <div class="people-info-text fl">
                            <!--<h1 class="over" v-if="delivery_data.pd_num != 4">{{ details_data.name }}</h1>-->
                            <!--<h1 class="over" v-else>{{ details_data.referrer_name }}</h1>-->
                            <h1 class="over">{{details_data.initiator_name}}</h1>
                            <h2 class="over">{{$t('myInterviewDetail.publisher')}}<!--发布者--></h2>
                            <!--<h2 class="over">-->
                            <!--  {{ details_data.company_name }} · {{ details_data.position }}-->
                            <!--</h2>-->
                        </div>
                    </div>
                </div>
                <div class="wrap2">
                    <h1>{{$t('myProject.freeCommunication')}}<!--免费沟通--></h1>
                    <div class="people-info">
                        <!-- <img class="head-icon fl" :src="details_data.avatar" alt="" /> -->
                        <img :src="details_data.invitee_avatar" alt="" class="head-icon fl"/>
                        <div class="people-info-text fl">
                            <!--<h1 class="over" v-if="delivery_data.pd_num != 4">{{ details_data.name }}</h1>-->
                            <!--<h1 class="over" v-else>{{ details_data.referrer_name }}</h1>-->
                            <h1 class="over">{{details_data.invitee_name}}</h1>
                            <h2 class="over">{{details_data.company_name}} · {{details_data.position}}</h2>
                            <!--<h2 class="over">-->
                            <!--  {{ details_data.company_name }} · {{ details_data.position }}-->
                            <!--</h2>-->
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="pl_type == 4">
                <MultiPerson/>
            </div>


            <!--预约时间-->
            <AppointmentTime :type="-1"/>

            <div class="project-detail">
                <!-- 翻译：项目详情-->
                <h1>{{ $t("vipRecommend.projectDetail") }}</h1>
                <div class="rich-text">
                    <p v-html="details_data.details"></p>
                    <!-- <img src="@/assets/images/rich_content.png" style="width: 695px" alt="" /> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import BreadcrumbNav from "@/components/BreadcrumbNav";
    import CustomButton from "@/components/my_project/vip_private_recommendation/CustomButton";
    import AppointmentTime from "@/components/my_project/my_interview/receive_interview_vip/AppointmentTime";
    import MultiPerson from "@/components/my_project/MultiPersonFreeCommunication";
    import {
        myJoinprojectDetails,
        myJoinmultiProDetails,
        inviteeFeedback,
    } from "@/api/projectAbout";
    import {initiatorFeedback, threePrivacyDetails} from "@/api/myProjectApi"

    export default {
        name: "index",
        components: {
            BreadcrumbNav,
            CustomButton,
            AppointmentTime,
            MultiPerson,
        },
        data() {
            return {
                type: 0, //0:未选择、1：同意、2：拒绝、3：预约时间、4：发布者已取消、5：您已取消
                pl_type: 0, //0:选择时间、1：预约成功、2：预约评论、3：显示评论、4：多人评论
                stateObject: {
                    isOnly: true,
                    communicateType: 0,
                },
                delivery_data: {},
                details_data: {},
            };
        },
        mounted() {
            this.delivery_data = this.$route.query;
            this.getProjectBasic();
            // this.get_projectDetails();
        },
        methods: {
            //免费沟通邀约详情页面-VIP私密推荐 A(发起者发起五个时间的页面)
            getProjectBasic() {
                let formData = {
                    detailType: 1,
                    interviewId: this.$route.query.id,
                    token: localStorage.getItem("token"),
                };
                threePrivacyDetails(formData).then((res) => {
                    this.details_data = res.data;
                });

            },

            //愿意免费沟通或拒绝
            async initiatorFeedbackForVip(form) {
                let formData = {
                    feedback: 1,
                    interviewId: this.$route.query.id,
                    inviteTime1: form.inviteTime1,
                    inviteTime2: form.inviteTime2,
                    inviteTime3: form.inviteTime3,
                    inviteTime4: form.inviteTime4,
                    inviteTime5: form.inviteTime5,
                    token: localStorage.getItem("token"),
                    rejectReason: '',
                    mobileCode: form.mobileCode,
                    mobileNumber: form.mobileNumber,
                    mailBox: form.mailBox,
                    whatsappCode: form.whatsappCode,
                    whatsApp: form.whatsApp,
                    inviteMessage: form.inviteMessage,
                };
                let res = await initiatorFeedback(formData);
                if (res.code == 200) {
                    this.$message.success(res.msg)
                    this.$router.go(-1)
                } else {
                    this.$message.success(res.msg)
                }
            },
            //   项目详情
            get_projectDetails() {
                let formData = {
                    token: localStorage.getItem("token"),
                };
                if (this.delivery_data.pd_num == "6") {
                    formData.joinId = this.delivery_data.joinId;
                    formData.projectId = this.delivery_data.project_Id;
                    this.pl_type = 0;
                    myJoinmultiProDetails(formData).then((res) => {
                        this.details_data = res.data;
                        console.log("3121", res);
                        console.log(res.data.progress);

                        //   if (res.data.progress) {
                        //     switch (res.data.progress) {
                        //       case "1":
                        //         this.type = 5;
                        //         break;
                        //       case "2":
                        //         this.type = 5;
                        //         break;
                        //       case "3":
                        //         this.type = 5;
                        //         break;
                        //       case "4":
                        //         this.type = 5;
                        //         break;
                        //       case "5":
                        //         this.type = 5;
                        //         break;
                        //       case "6":
                        //         this.type = 5;
                        //         break;
                        //       case "9":
                        //         this.type = 5;
                        //         break;
                        //     }
                        //   }
                    });
                } else {
                    formData.mainId = this.delivery_data.project_Id;
                    formData.dataType = this.delivery_data.pd_num;
                    this.pl_type = 0;
                    myJoinprojectDetails(formData).then((res) => {
                        this.details_data = res.data;
                        console.log("312122222222", res);
                        if (res.data.progress) {
                            // res.data.progress = "1";
                            console.log("312122222222", res.data.progress);
                            switch (res.data.progress) {
                                //  选择预约时间
                                case "1":
                                    this.type = 3;
                                    break;

                                case "2":
                                    this.type = 2;
                                    break;

                                case "3":
                                    this.type = 3;
                                    break;
                                case "4":
                                    this.type = 5;
                                    break;
                                // 预约成功
                                case "5":
                                    this.type = 3;
                                    this.pl_type = 1;

                                    break;

                                case "6":
                                    this.type = 5;
                                    break;
                                // 受邀人评论
                                case "7":
                                    this.type = 3;
                                    this.pl_type = 2;
                                    break;
                                // 受邀人已经评论
                                case "8":
                                    this.type = 3;
                                    this.pl_type = 3;

                                    break;
                                case "9":
                                    this.type = 5;
                                    break;
                                case "10":
                                    this.type = 5;
                                    break;
                            }
                            console.log(this.type);
                            console.log(this.pl_type);
                        }
                    });
                }
            },
            getMsgFormSon(data) {
                console.log(data);
            },
        },
    };
</script>

<style scoped>
    .wrap1 {
        box-sizing: border-box;
        height: 142px;
        padding-top: 38px;

        border-top: 1px solid #E5E5E5;
        border-bottom: 1px solid #E5E5E5;
    }

    .wrap2 {
        padding-top: 38px;
        margin-bottom: 39px;
    }

    .wrap2 > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 31px;
        color: #000000;
        margin-bottom: 34px;
    }

    .people-info {
        overflow: hidden;
    }

    .container {
        background-color: #ffffff;
    }

    .main {
        width: 1200px;
        padding-top: 14px;
        margin: 0 auto;
    }

    .text-group1 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        border-bottom: 1px solid #e5e5e5;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .text-group1 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .text-group1 > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-top: 8px;
    }

    .text-group2 {
        box-sizing: border-box;
        width: 100%;
        min-height: 120px;
        padding-top: 30px;
    }

    .text-group2-content {
        margin-top: 8px;
    }

    .publish-date {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
    }

    .brief-intro {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #707070;
        margin-left: 16px;
    }

    .text-group2 > h1 {
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .people-wrap {
        overflow: hidden;
    }

    .people-wrap .head-icon {
        width: 67px;
        height: 67px;
        background: rgba(0, 0, 0, 0);
        border-radius: 50%;
    }

    .people-info-text {
        margin-left: 13px;
        min-width: 160px;
        max-width: 300px;
    }

    .people-info-text > h1 {
        font-size: 17px;
        font-family: BentonSans;
        font-weight: 500;
        color: #000000;
        margin-top: 8px;
    }

    .people-info-text > h2 {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #949494;
        margin-top: 13px;
        margin-left: 4px;
    }

    .people-wrap .btn-group {
        margin-top: 4px;
        margin-left: 136px;
    }

    .reason {
        margin-top: 65px;
    }

    .reason > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .reason > p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #000000;
        margin-top: 10px;
    }

    .is-btn-group {
        margin-top: 38px;
        overflow: hidden;
    }

    .project-detail {
        margin-top: 72px;
    }

    .project-detail > h1 {
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
    }

    .project-detail .rich-text {
        margin-top: 42px;
    }
</style>
